import cx from "classnames"
import moment from "moment"
import React, { useState, useMemo, useCallback, useEffect } from "react"
import { Container } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils.js"
import { OverviewSectionTabs } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionsView from "./ActionsView"
import Details from "../ActionDetails"
import InspectionDetails from "./inspection-details"
import { SECTION_TAB_TYPES, confirmSiteVisitDetailSectionTabs } from '../config'
import { AuditTrial } from "../../components"
import { ACTION_TYPES, NOT_READY_RESPONSE_ID_LIST } from "../../config"
import { getDateTimeFormat, getStartTimeOptions } from "../../../../helpers"

const { ACTIONS_PAGE, ACTIONS_DESCRIPTION } = LANGUAGE_CONSTANTS

const getActionsDetailSectionComponent = (activeSectionTabId) => {
  switch (activeSectionTabId) {
    case SECTION_TAB_TYPES.INSPECTION_DETAILS:
      return InspectionDetails
    case SECTION_TAB_TYPES.AUDIT_TRAIL:
      return AuditTrial
    default:
      return InspectionDetails
  }
}

const ConfirmSiteVisit = ({
  intl,
  item,
  isToggleDetails,
  onGoBack = () => { },
  onToggleDetails = () => { }
}) => {
  const dispatch = useDispatch()
  const { isMobileView } = useSelector((state) => state.appConfig)

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [activeSectionTabId, setActiveSectionTabId] = useState(SECTION_TAB_TYPES.INSPECTION_DETAILS)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedNotReadyAction, setSelectedNotReadyAction] = useState(null)
  const [rescheduleDate, setRescheduleDate] = useState(null)
  const [isOpenRescheduleDatePicker, setOpenRescheduleDatePicker] = useState(false)
  const [actionListVM, setActionListVM] = useState({})

  const handleFetchActionListVM = useCallback(() => {
    dispatch(actions.getActionListVMRequest((data) => {
      if (data) {
        setActionListVM({
          actionNotReadyResponse: data.actionNotReadyResponse
        })
      }
    }))
  }, [])

  useEffect(() => {
    handleFetchActionListVM()
  }, [])

  const handleGoToOnSubmittingAction = useCallback(() => {
    onGoBack()
  }, [onGoBack])

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setRescheduleDate(null)
    setSelectedNotReadyAction(null)
    setNoteDetail("")
  }, [])

  const handleChangeDetails = useCallback((key, value) => {
    if (key === "rescheduleDate") {
      setRescheduleDate(value)
      setSelectedNotReadyAction(NOT_READY_RESPONSE_ID_LIST.RE_SCHEDULE_DATE)
      setOpenRescheduleDatePicker(false)
    } else {
      if (value === NOT_READY_RESPONSE_ID_LIST.RE_SCHEDULE_DATE) {
        setOpenRescheduleDatePicker(true)
      } else {
        setSelectedNotReadyAction(value)
        setRescheduleDate(null)
      }
    }
  }, [])

  const handleConfirmAction = useCallback(() => {
    if (selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY) {
      setShowSubmitActionView(true)
    } else {
      const payload = {
        observationTaskId: item.observationTaskId,
        isConfirmSiteVisit: true
      }
      dispatch(actions.createConfirmSiteVisitRequest(payload, (res) => {
        if (res) {
          handleGoToOnSubmittingAction()
          handleResetState()
        }
      }))
    }
  }, [
    item,
    selectedActionType,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
    if (!value) {
      setRescheduleDate(null)
      setSelectedNotReadyAction(null)
    }
    setShowSubmitActionView(false)
  }, [])

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleCloseDatePicker = useCallback(() => {
    setOpenRescheduleDatePicker(false)
    setSelectedNotReadyAction(null)
  }, [])

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView((prevState) => !prevState)

    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("")
    }
  }, [isShowAddNoteView])

  const handleSubmitAndClose = useCallback(() => {
    const payload = {
      observationTaskId: item.observationTaskId,
      isConfirmSiteVisit: false,
      note: noteDetail
    }

    if (rescheduleDate) {
      payload.rescheduleDate = getDateTimeFormat(rescheduleDate)
    }

    if (selectedNotReadyAction) {
      payload.actionNotReadyResponseId = Number(selectedNotReadyAction)
    }

    dispatch(actions.createConfirmSiteVisitRequest(payload, (res) => {
      if (res) {
        handleGoToOnSubmittingAction()
        handleResetState()
      }
    }))
  }, [
    rescheduleDate,
    item,
    noteDetail,
    selectedNotReadyAction,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleSelectTab = useCallback((activeTabId) => {
    setActiveSectionTabId(activeTabId)
  }, [])

  const Component = getActionsDetailSectionComponent(activeSectionTabId)

  return (
    <div className="action-detail-outer">
      <Container>
        <div className="details-view">

          <div className="action-detail-list">
            <div
              className={cx("", {
                "action-header": isMobileView
              })}>

              <h6 className="action-detail-title">
                {item.taskOrActivityName}
              </h6>

              {isMobileView && <button
                type="button"
                className="advanced-btn"
                onClick={onToggleDetails}
              >
                {isToggleDetails ? <ChevronUp /> : <ChevronDown />}
              </button>}

            </div>
            <p className="action-detail-sub-title">
              {getLocaleMessage(intl, ACTIONS_PAGE.CONFIRM_SITE_VISIT)}
            </p>
            <p>
              {getLocaleMessage(intl, ACTIONS_DESCRIPTION)}
            </p>
          </div>

          {isMobileView ? isToggleDetails ? <Details item={item} /> : "" : <Details item={item} />}

          <ActionsView
            actionListVM={actionListVM}
            rescheduleDate={rescheduleDate}
            isOpenRescheduleDatePicker={isOpenRescheduleDatePicker}
            isShowAddNoteView={isShowAddNoteView}
            isShowSubmitActionView={isShowSubmitActionView}
            noteDetail={noteDetail}
            selectedActionType={selectedActionType}
            selectedNotReadyAction={selectedNotReadyAction}
            actionStatus={item.actionStatus}
            onChangeDetails={handleChangeDetails}
            onChangeNoteDetail={handleChangeNoteDetail}
            onCloseDatePicker={handleCloseDatePicker}
            onConfirmAction={handleConfirmAction}
            onShowConfirmActionView={handleShowConfirmActionView}
            onSubmitAndClose={handleSubmitAndClose}
            onToggleAddNote={handleToggleAddNote}
          />
        </div>
      </Container>
      <div className="section-tabs-border">
        <Container>
          <div className="mb-1 mt-1">
            <OverviewSectionTabs
              activeTabId={activeSectionTabId}
              tabsList={confirmSiteVisitDetailSectionTabs}
              isBorderBottomRequired={false}
              onChangeTab={handleSelectTab}
            />
          </div>
          {!!Component && <Component
            actionId={item.actionId}
            observationTaskId={item.observationTaskId}
          />}
        </Container>
      </div>
    </div>
  )
}

export default localeMessageWrapper(ConfirmSiteVisit)
