import cx from "classnames"
import React from "react"

import { map, person, note, calendar } from "assets/images/actions"
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage, getFormattedDate } from "utility/Utils"
import localeMessageWrapper from "views/components/locale-message"

const { N_A } = LANGUAGE_CONSTANTS

const Details = ({
  intl,
  isShowFullNotes,
  onShowFullNotes,
  isShowFullActivityName,
  onShowFullActivityName,
  item: {
    actionId,
    taskOrActivityName,
    siteName,
    dueDate,
    assignee,
    responseNote = ""
  }
}) => {

  return (
    <>
      <div className="d-flex align-items-start d-flex justify-content-between mb-2">
        <h4 className={cx("action-type-name", { expanded: isShowFullActivityName })} onClick={onShowFullActivityName}>
          {taskOrActivityName}
        </h4>
        <span className="task-date"><img src={calendar} />{getFormattedDate(dueDate, "M/DD")}</span>
        {/* {selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY &&
          isShowSubmitActionView && (
            <div className="d-flex align-items-center not-ready-btn card-action-btn">
              <span className="btn btn-outline-danger not-ready-btn mr-1">{getLocaleMessage(intl, ACTIONS_PAGE.NOT_READY)}</span>
              <ActionMenu
                actionId={actionId}
                isCardView
              />
            </div>
          )} */}
      </div>
      <p className="task-map"> <img src={map} />
        {siteName}
      </p>
      <p className="task-map">
        <img src={person} /> {assignee}
      </p>
      <p className="task-note-discription"> <img src={note} />
        <span className={cx("task-note", { expanded: isShowFullNotes })} onClick={onShowFullNotes}>
          {responseNote || getLocaleMessage(intl, N_A)}
        </span></p>
    </>
  )
}

export default localeMessageWrapper(Details)
