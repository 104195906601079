export const ACTION_TASK_CARD_TYPES = {
  CONFIRM_SITE_VISIT: "2",
  FAILED_INSPECTION_CORRECTION: "3",
  VERIFY_BY_SUPERINTENDENT: "4",
  REASSIGN_FAILED_INSPECTION_CORRECTION: "5"
}

export const ACTION_STATUS_VALUE_TYPES = {
  COMPLETED: "Completed"
}

export const ACTION_TYPES = {
  FAILED_INSPECTION_CORRECTION: {
    CORRECTED: "corrected",
    REASSIGN: "reassign"
  },
  VERIFY_BY_SUPERINTENDENT: {
    APPROVE: "approve",
    REJECT: "reject"
  },
  CONFIRM_SITE_VISIT: {
    CONFIRM: "confirm",
    NOT_READY: "notReady"
  }
}

export const VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES = {
  NOT_ACCEPT: 0,
  ACCEPT: 1,
  NO_RESPONSE: 2
}

export const NOT_READY_RESPONSE_ID_LIST = {
  RE_SCHEDULE_DATE: "1",
  TO_BE_PROVIDED: "2",
  PLEASE_CONTACT_ME: "3"
}
