import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import * as actions from "store/actions"
import { getDateTimeFormat, getStartTimeOptions } from "views/helpers"
import ActionsView from "./ActionsView"
import Details from "./Details"
import { ACTION_TYPES, NOT_READY_RESPONSE_ID_LIST } from "../../../config"

const ConfirmSiteVisit = ({
  actionListVM = {},
  item,
  isOverviewCardView,
  onViewMore = () => { },
  onRefreshActionList = () => { }
}) => {
  const { control } = useForm()
  const dispatch = useDispatch()

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedNotReadyAction, setSelectedNotReadyAction] = useState(null)
  const [rescheduleDate, setRescheduleDate] = useState(null)
  const [isOpenRescheduleDatePicker, setOpenRescheduleDatePicker] = useState(false)
  const [isShowFullActivityName, setShowFullActivityName] = useState(false)
  const [isShowFullNotes, setShowFullNotes] = useState(false)

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setRescheduleDate(null)
    setSelectedNotReadyAction(null)
    setNoteDetail("")
  }, [])

  const handleChangeDetails = useCallback((key, value) => {
    if (key === "rescheduleDate") {
      setRescheduleDate(value)
      setSelectedNotReadyAction(NOT_READY_RESPONSE_ID_LIST.RE_SCHEDULE_DATE)
      setOpenRescheduleDatePicker(false)
    } else {
      if (value === NOT_READY_RESPONSE_ID_LIST.RE_SCHEDULE_DATE) {
        setOpenRescheduleDatePicker(true)
      } else {
        setSelectedNotReadyAction(value)
        setRescheduleDate(null)
      }
    }
  }, [])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
    if (!value) {
      setRescheduleDate(null)
      setSelectedNotReadyAction(null)
    }
    setShowSubmitActionView(false)
  }, [])

  const handleConfirmAction = useCallback(() => {
    if (selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY) {
      setShowSubmitActionView(true)
    } else {
      const payload = {
        observationTaskId: item.observationTaskId,
        isConfirmSiteVisit: true
      }
      dispatch(
        actions.createConfirmSiteVisitRequest(payload, (res) => {
          if (res) {
            onRefreshActionList()
            handleResetState()
          }
        })
      )
    }
  }, [
    item,
    selectedActionType,
    handleResetState,
    onRefreshActionList
  ])

  const handleViewMore = useCallback(() => {
    onViewMore(item.actionId)
  }, [item.actionId, onViewMore])

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleCloseDatePicker = useCallback(() => {
    setOpenRescheduleDatePicker(false)
    setSelectedNotReadyAction(null)
  }, [])

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView((prevState) => !prevState)
    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("")
    }
  }, [isShowAddNoteView])

  const handleSubmit = useCallback(() => {
    const payload = {
      observationTaskId: item.observationTaskId,
      isConfirmSiteVisit: false,
      note: noteDetail
    }

    if (rescheduleDate) {
      payload.rescheduleDate = getDateTimeFormat(rescheduleDate)
    }

    if (selectedNotReadyAction) {
      payload.actionNotReadyResponseId = Number(selectedNotReadyAction)
    }

    dispatch(
      actions.createConfirmSiteVisitRequest(payload, (res) => {
        if (res) {
          onRefreshActionList()
          handleResetState()
        }
      })
    )
  }, [
    rescheduleDate,
    item,
    noteDetail,
    selectedNotReadyAction,
    handleResetState,
    onRefreshActionList
  ])

  const handleShowFullActivityName = useCallback(() => {
    if (isShowFullActivityName) {
      return
    }
    setShowFullActivityName(true)
  }, [isShowFullActivityName])

  const handleShowFullNotes = useCallback(() => {
    if (isShowFullNotes) {
      return
    }
    setShowFullNotes(true)
  }, [isShowFullNotes])


  return (
    <div className="action-card">
      <Details
        item={item}
        isShowSubmitActionView={isShowSubmitActionView}
        selectedActionType={selectedActionType}
        isShowFullActivityName={isShowFullActivityName}
        isShowFullNotes={isShowFullNotes}
        onShowFullNotes={handleShowFullNotes}
        onShowFullActivityName={handleShowFullActivityName}
      />
      <ActionsView
        actionListVM={actionListVM}
        actionId={item.actionId}
        actionStatus={item.actionStatus}
        rescheduleDate={rescheduleDate}
        control={control}
        isOpenRescheduleDatePicker={isOpenRescheduleDatePicker}
        isOverviewCardView={isOverviewCardView}
        isShowAddNoteView={isShowAddNoteView}
        isShowSubmitActionView={isShowSubmitActionView}
        noteDetail={noteDetail}
        selectedActionType={selectedActionType}
        selectedNotReadyAction={selectedNotReadyAction}
        onChangeDetails={handleChangeDetails}
        onChangeNoteDetail={handleChangeNoteDetail}
        onCloseDatePicker={handleCloseDatePicker}
        onConfirmAction={handleConfirmAction}
        onShowConfirmActionView={handleShowConfirmActionView}
        onSubmit={handleSubmit}
        onToggleAddNote={handleToggleAddNote}
        onViewMore={handleViewMore}
      />
    </div>
  )
}

export default ConfirmSiteVisit
