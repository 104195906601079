import { getSearchQuery } from '../config/index'
import AppSettings from '../../AppSettings.json'

const apiRouteConfig = {
  getActionList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      advanceSearch = {},
    }) => {
      let searchString = `${getSearchQuery("PageNumber", pageNumber, true)}${getSearchQuery(
        "PageSize",
        pageSize
      )}`

      if (!!Object.keys(advanceSearch).length) {
        const { actionTypeId, siteId, siteUnitId, organizationId, entityId } = advanceSearch
        searchString = `${searchString}${getSearchQuery(
          "WFActionTypeId",
          actionTypeId
        )}${getSearchQuery("SiteId", siteId)}${getSearchQuery("SiteUnitId",
          siteUnitId)}${getSearchQuery("OrganizationId",
            organizationId)}${getSearchQuery("EntityId",
              entityId)}`
      }
      return `api/FSPublic/Action/ActionList${searchString}`
    }
  },
  createConfirmSiteVisit: {
    method: 'POST',
    url: ({
      observationTaskId,
      isConfirmSiteVisit,
      rescheduleDate,
      actionNotReadyResponseId,
      note
    }) => {
      const searchString = [getSearchQuery("ReScheduleDate", rescheduleDate), getSearchQuery("ActionNotReadyResponseId", actionNotReadyResponseId), getSearchQuery("Note", note, false, true)].join('')
      return `api/FSPublic/confirmsitevisit/${observationTaskId}?IsConfirmSiteVisit=${isConfirmSiteVisit}${searchString}`
    }
  },
  updateAcceptByTrade: {
    method: 'PUT',
    url: ({ wfActionId }) => `api/FSPublic/AcceptByTrade/${wfActionId}`
  },
  updateTradeActionReassign: {
    method: 'PUT',
    url: ({ actionId, contactId, roleId }) => `api/FSPublic/TradeActionReassign/${actionId}/${contactId}/${roleId}`
  },
  getActionDetail: {
    method: 'GET',
    url: ({ actionId }) => `api/FSPublic/Action/ActionDetail/${actionId}?ActionId=${actionId}`
  },
  updateVerifyBySuperintendent: {
    method: 'PUT',
    url: ({
      wfActionId
    }) => `api/FSPublic/VerifyBySuperintendent/${wfActionId}`
  },
  updateReassignFailedInspection: {
    method: 'PUT',
    url: ({ actionId, contactId, roleId }) => `api/FSPublic/ReassignFailedInspection/${actionId}/${contactId}/${roleId}`
  },
  getActionListVM: {
    method: 'GET',
    url: "api/FSPublic/Action/vm/ActionList"
  },
  getReassignFailedInspectionContact: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize, contactName }) => {
      const searchString = `${getSearchQuery("PageNumber", pageNumber, true)}${getSearchQuery(
        "PageSize",
        pageSize
      )}${getSearchQuery("ContactName", contactName)}`
      return `api/FSPublic/GetReassignFailedInspectionContact/${actionId}${searchString}`
    }
  },
  getTradeContactToReassign: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize, contactName }) => {
      const searchString = `${getSearchQuery("PageNumber", pageNumber, true)}${getSearchQuery(
        "PageSize",
        pageSize
      )}${getSearchQuery("ContactName", contactName)}`
      return `api/FSPublic/GetTradeContacttoReassign/${actionId}${searchString}`
    }
  },
  getActionSiteListDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      siteName,
      organisationId
    }) => {
      const searchString = `${getSearchQuery("PageNumber", pageNumber, true)}${getSearchQuery(
        "PageSize",
        pageSize
      )}${getSearchQuery("SiteName", siteName)}
      ${getSearchQuery("OrganisationId",
        organisationId)}
      `
      return `api/FSPublic/GetSiteListForActionByContact/${organisationId}${searchString}`
    }
  },
  getInspectionDetail: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize, }) => {
      const searchString = `${getSearchQuery("PageNumber", pageNumber, true)}${getSearchQuery(
        "PageSize",
        pageSize
      )}`
      return `api/FSPublic/InspectionDetail/${actionId}${searchString}`
    }
  },
  getActionUnitListDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      unitName,
      siteId
    }) => {
      const searchString = `${getSearchQuery("PageNumber",
      pageNumber, true)}${getSearchQuery("PageSize",
      pageSize)}${getSearchQuery("UnitName",
      unitName)}${getSearchQuery("SiteId",
      siteId)}`
      return `api/FSPublic/GetUnitListForActionByContact/${siteId}${searchString}`
    }
  },
  getBuilderListDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      organizationName,
      entityId
    }) => {
      const searchString = `${getSearchQuery("PageNumber",
        pageNumber, true)}${getSearchQuery("PageSize",
          pageSize)}${getSearchQuery("OrganizationName",
            organizationName)}${getSearchQuery("EntityId",
              entityId)}`
      return `api/FSPublic/GetOrganizationListForActionByContact/${entityId}${searchString}`
    }
  },
  getEntityListDDL: {
    method: 'GET',
    url: "api/FSPublic/GetEntityListForActionByContact"
  }
};

const apiConfig = {
  API_SUBSCRIPTION_KEY: AppSettings.REACT_APP_API_SUBSCRIPTION_KEY,
  BASE_URL: `${AppSettings.REACT_APP_API_ORIGIN}/`
};

export { apiConfig, apiRouteConfig };

export default apiRouteConfig;
