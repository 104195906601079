export const LANGUAGE_CONSTANTS = {
  MY_ASSIGNED: "My Assigned",
  ACTIONS: "Actions",
  ACTIONS_DESCRIPTION: "Actions are time-senstive items that focuses on one core function. Complete these as soon as you can.",
  BACK: "Back",
  VIEW_ALL: "View All",
  ACTIONS_FILTER: {
    FILTER: "Filter",
    CLEAR: "Clear",
    FILTER_BY_ACTION_TYPE: "Filter by Action Type",
    FILTER_BY_ASSIGNED_BY: "Filter by Assigned By",
    FILTER_BY_BUILDER: "Filter by Builder",
    FILTER_BY_SITE: "Filter by Action Site",
    FILTER_BY_UNIT: "Filter by Action Unit",
    ASSIGNED_BY: "Assigned By",
    BUILDER: "Builder",
    ACTION_SITE: "Action Site",
    ACTION_UNIT: "Action Unit",
    ACTION_TYPE: "Action Type"
  },
  N_A: "N/A",
  ACTIONS_PAGE: {
    CONFIRM: 'Confirm',
    VIEW_MORE: 'view more',
    NOT_READY: 'Not Ready',
    CORRECTED: "Corrected",
    REASSIGN: "reassign",
    APPROVE: "approve",
    REJECT: "reject",
    YES_ITS_READY: "Yes it's Ready",
    NO_ITS_NOT_READY: "No it's Not Ready",
    CORRECTED_ACTION: "Yes, it's Corrected",
    REASSIGN_ACTION: "Yes, Reassign Task",
    VIEW_ADD_NOTES: "View / Add Notes",
    VIEW_ADD_PHOTO: "View / Add Photo",
    APPROVE_ACTION: "Yes, Approve Correction",
    REJECT_ACTION: "Yes, I Reject Correction",
    ADD_NOTE: "Add Note",
    HIDE_NOTE: "Hide Note",
    ADD_PHOTO: "Add Photo",
    VIEW_PHOTOS: "View Photos",
    ATTATCHED_PHOTOS: "Add Photo ({count})",
    SUBMIT_AND_CLOSE: "Submit and Close",
    SUBMIT: "Submit",
    ADD_NOTE_PLACEHOLDER: "Write your note here...",
    PHOTO_ATTACHMENTS_COUNT_SINGULER_TEXT: "{count} Attachment",
    PHOTO_ATTACHMENTS_COUNT_PLURAL_TEXT: "{count} Attachments",
    CONTACT_TO_ASSIGN_CORRECTION: "Select Contact to Assign Correction",
    DUE_DATE: "Due Date",
    STATUS: "Status",
    SCHEDULED: "Scheduled",
    ASSIGNEE: "Assignee",
    LOCATION: "Location",
    INSPECTION_DETAILS: "Inspection Details",
    CORRECTION_DETAILS: "Correction Details",
    OBSERVATION_DETAILS: "Observation Details",
    ACTIVITY_DETAILS: "Activity Details",
    AUDIT_TRAIL: "Audit Trail",
    READY_DATE: "Ready Date",
    READY_TIME: "Ready Time",
    CONFIRM_SITE_VISIT: "Confirm/Reschedule Task",
    SELECT_CONTACT: "Select Contact",
    ADD: "Add",
    ADD_AND_CLOSE: "Add & Close",
    CANCEL: "Cancel",
    UPLOAD: "Upload",
    RESOURCES: {
      TITLE: "Title",
      FILE: "File",
      NOTES: "Notes",
      RESOURCE_CENTER_UPLOADER_DESCRIPTION: "Drag 'n' drop some files here, or click to select files",
      RESOURCE_CENTER_UPLOADER_SUB_DESCRIPTION: "Only files with size less than 4MB will be accepted",
      FILE_TITLE_REQUIRED: "File title is required",
      FILE_SIZE_INVALID: "Some files are rejected, as only files with size less than 4MB will be accepted",
    },
    ATTACHED_PHOTOS_LABEL: "({count}) Photos Attached",
    VERIFY_BY_SUPERINDENDENT: "verify by superindendent",
    FAILED_INSPECTION_CORRECTION: "failed inspection correction",
    REASSIGN_FAILED_INSPECTION_CORRECTION: "reassign failed inspection correction",
    NO_DATA: "All Assigned Actions Completed",
    NO_DATA_FOR_FILTERS: "You have no task/activity actions matched with filters you applied.",
    AUDIT_TRAIL: "Audit Trail",
    CREATED: "Created:",
    SUBMITTER: "Submitter:",
    REASON_FOR_NOT_READY: "Reason for not ready:",
    PRIMARY_NOTE: "Primary Note",
    ATTACHED_NOTE: "Attached Note",
    OBSERVATION_DETAILS: "Observation Details",
    ACTIVITY_DETAILS: "Activity Details",
    NO_ACTIVITY_DETAILS: "No Activity Details",
    VIEW_NOTE: "View Note",
    HIDE_NOTE: "Hide Note",
    REMOVE_NOTE: "Remove Note",
    NOT_READY_WARNING_TEXT: "{attentionText} If you are cancelling outside the window established in your contract or requesting a revised date/time with short notice, a dry run or same day fee may be assessed (your scheduler will contact you). If you don't know when the unit will be ready, or will reschedule via your project management platform (BuildPro, etc.) please select \"{toBeProvidedText}\". If you would like a scheduler to contact you, please select \"{pleaseContactMeText}\".",
    ATTENTION: "Attention:",
    TO_BE_PROVIDED: "To Be Provided",
    PLEASE_CONTACT_ME: "Please Contact Me",
    SELECT_OPTION: "Select Option"
  },
  ACTIONS_PAGE_SAGA: {
    ACCEPT_BY_TRADE_UPDATE_SUCCESS: "Correction acceptance confirmed successfully.",
    ERROR_IN_UPDATING_ACCEPT_BY_TRADE: "Error in correction acceptance confirmation.",
    UPDATE_CONTACT_TRADE_REASSIGN_SUCCESS: "Contact trade reassigned successfully.",
    UPDATE_REASSIGN_FAILED_INSPECTION_SUCCESS: "Reassign failed inspection updated successfully."
  },
  LOADER: {
    LOADING: "Loading..."
  },
  ACTIVITIES: {
    RISK_LEVEL: "Risk Level",
    RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
    ASSEMBLY: "Assembly",
    ASSEMBLY_TYPE: "Assembly Type",
    MAIN_COMPONENT: "Main Component",
    SUB_COMPONENT: "Sub Component",
    MANUFACTURER: "Manufacturer",
    BRAND: "Brand",
    REGULATION_NAME: "Regulation Name",
    STANDARD_NAME: "Standard Name"
  },
  RESPONSES_PLACEHOLDER_TEXT: {
    WRITE_TEXT_RESPONSE: "write text response",
    SELECT_DATE: "select date",
    SELECT_RESPONSE: "select response",
    ENTER_WHOLE_NUMBER: "Enter Number (ex. 10)",
    ENTER_PERCENTAGE: "Enter Percentage",
    ENTER_DECIMAL_NUMBER: "Enter Number (ex. 10.37)",
    TO: "to"
  },
  UNGROUPED_ACTIVITY: "Ungrouped",
  ADDITIONAL_NOTE: "Additional Note",
  TEXT: "Text",
  VIEW_JSON_OBJECT: "View JSON Object",
  JSON_DATA_VIEW_PLACEHOLDER: "No JSON Object to be shown here.",
  TRADE_CORRECTION: {
    TRADE_CORRECTION_DETAILS: "Trade Correction Details",
    SELECT_TRADE: "Select Trade",
    SELECT_TRADE_CONTACT: "Select Trade Contact",
    ATTACH_NOTE_FOR_TRADE: "Attach Note for Trade",
    NOTE_PLACEHOLDER: "Write Description..."
  },
  YOU_ARE_NOT_AUTHORIZED: 'You are not authorized!',
}