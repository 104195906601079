import React, { useMemo, useRef, useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { MessageCard } from "views/components"
import localeMessageWrapper, { LocaleMessage } from "views/components/locale-message"
import ConfirmSiteVisit from "./cards/confirm-site-visit"
import FailedInspectionCorrection from "./cards/failed-inspection-correction"
import ReassignFailedInspectionCorrection from "./cards/reassign-failed-inspection-correction"
import VerifyBySuperindendent from "./cards/verify-by-superindendent"
import { ACTION_TASK_CARD_TYPES } from "../config"
import { getLastElementObserver } from "../helpers"

const getCardComponent = (actionTypeId) => {
  switch (String(actionTypeId)) {
    case ACTION_TASK_CARD_TYPES.CONFIRM_SITE_VISIT:
      return ConfirmSiteVisit
    case ACTION_TASK_CARD_TYPES.FAILED_INSPECTION_CORRECTION:
      return FailedInspectionCorrection
    case ACTION_TASK_CARD_TYPES.VERIFY_BY_SUPERINTENDENT:
      return VerifyBySuperindendent
    case ACTION_TASK_CARD_TYPES.REASSIGN_FAILED_INSPECTION_CORRECTION:
      return ReassignFailedInspectionCorrection
    default:
      return null
  }
}

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const ActionCards = ({
  intl,
  actionListVM = {},
  actionList: {
    list = [],
    totalCount = 0,
    hasNextPage = false
  },
  isFetching,
  isFetchingMore,
  isSearchApplied,
  onRefreshActionList = () => { },
  onViewMore = () => { },
  onLoadMore = () => { }
}) => {

  const observerRef = useRef(null)
  const lastActionIndex = useMemo(() => list.length - 1, [list])

  /**
    * lastElement: Used for auto loading next options, when user scroll to last visible element in options list
    */
  const [lastElement, setLastElement] = useState(null)


  //To get last element in option list from DOM
  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (!list?.length) {
          return null
        }
        const lastEleDetail = list[list.length - 1]
        if (!lastEleDetail) {
          return null
        }

        setLastElement(
          document.getElementById('last-action-item')
        )
      })
    })()
  }, [list])

  useEffect(() => {
    if (hasNextPage && lastElement) {
      observerRef.current = getLastElementObserver({ onLoadMore: onLoadMore })

      // Observe the last element
      if (observerRef.current) {
        observerRef.current.observe(lastElement)
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [hasNextPage, lastElement])

  if (!list.length || isFetching) {
    return (
      <div className="message-card-box">
        <MessageCard
          messageText={getLocaleMessage(
            intl,
            isFetching
              ? LANGUAGE_CONSTANTS.LOADER.LOADING
              : isSearchApplied
                ? ACTIONS_PAGE.NO_DATA_FOR_FILTERS
                : ACTIONS_PAGE.NO_DATA
          )}
        />
      </div>
    )
  }

  return (
    <div>
      {!isFetching && <h6 className='actions-head'>
        <LocaleMessage id={LANGUAGE_CONSTANTS.ACTIONS} /> : {totalCount}
      </h6>}
      <Row className="action-outer-scroll">
        {list.map((item, index) => {
          const CardComponent = getCardComponent(item.actionTypeId)

          if (!CardComponent) {
            return null
          }

          return (
            <Col sm={12} md={12} xl={12} lg={12} id={lastActionIndex === index ? 'last-action-item' : ''}>
              <CardComponent
                key={`task_card_${item.actionId}_${index}`}
                actionListVM={actionListVM}
                item={item}
                onViewMore={onViewMore}
                onRefreshActionList={onRefreshActionList}
              />
            </Col>
          )
        })}
        {isFetchingMore && list.length && <div className="d-flex justify-content-center w-100">
          {getLocaleMessage(
            intl,
            LANGUAGE_CONSTANTS.LOADER.LOADING
          )}
        </div>}
      </Row>
    </div>

  )
}

export default localeMessageWrapper(ActionCards)
