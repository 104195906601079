import cx from "classnames"
import React, { useMemo } from "react"
import { Col, Input, Row } from "reactstrap"
import { components } from 'react-select'

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage, getFormattedDate } from "utility/Utils"
import { ReactSelect, DatePicker } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionButton from "../../../ActionButton"
import { ACTION_STATUS_VALUE_TYPES, ACTION_TYPES, NOT_READY_RESPONSE_ID_LIST } from "../../../config"

const { ACTIONS_PAGE, BACK } = LANGUAGE_CONSTANTS

const ActionsView = ({
  actionListVM = {},
  actionStatus,
  selectedNotReadyAction,
  rescheduleDate,
  control,
  noteDetail,
  isOpenRescheduleDatePicker,
  isShowAddNoteView,
  intl,
  selectedActionType,
  isShowSubmitActionView,
  onChangeDetails = () => { },
  onChangeNoteDetail = () => { },
  onCloseDatePicker = () => { },
  onConfirmAction = () => { },
  onShowConfirmActionView = () => { },
  onSubmit = () => { },
  onToggleAddNote = () => { },
  onViewMore = () => { }
}) => {

  const isDisabledView = useMemo(() => {
    return !(selectedNotReadyAction)
  }, [selectedNotReadyAction])

  const actionNotReadyResponseVM  = useMemo(() => actionListVM.actionNotReadyResponse || [], [actionListVM])

  const SingleValue = ({
    children,
    ...props
  }) => {
    return (
      <components.SingleValue {...props}>{selectedNotReadyAction === NOT_READY_RESPONSE_ID_LIST.RE_SCHEDULE_DATE ? getFormattedDate(rescheduleDate, 'MM/DD/YYYY') : children}</components.SingleValue>
    )
  }

  if (!isShowSubmitActionView) {
    return (
      <div className="d-flex align-items-center card-action-btn">
        {!selectedActionType ? (
          <>
            {actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && (
              <>
                <ActionButton
                  color="success"
                  className="mr-1 w-100"
                  labelLocaleMessageId={ACTIONS_PAGE.CONFIRM}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM}
                  onClick={onShowConfirmActionView}
                />
                <ActionButton
                  color="danger"
                  className="mr-1 w-100"
                  labelLocaleMessageId={ACTIONS_PAGE.NOT_READY}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY}
                  onClick={onShowConfirmActionView}
                />
              </>
            )}

            <ActionButton
              className="secondary-solid outline-button w-100"
              labelLocaleMessageId={ACTIONS_PAGE.VIEW_MORE}
              onClick={onViewMore}
            />

          </>
        ) : (
          <>
            {selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM ? (
              <ActionButton
                color="success"
                className="mr-1 to-capitalized w-100"
                isConfirmAction
                labelLocaleMessageId={ACTIONS_PAGE.YES_ITS_READY}
                onClick={onConfirmAction}
              />
            ) : (
              <ActionButton
                color="danger"
                className="mr-1 to-capitalized w-100"
                labelLocaleMessageId={ACTIONS_PAGE.NO_ITS_NOT_READY}
                isConfirmAction
                onClick={onConfirmAction}
              />
            )}
            <ActionButton
              className="secondary-solid mr-1 w-100"
              labelLocaleMessageId={BACK}
              onClick={onShowConfirmActionView}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Row className="action-input">
      {/* <Row xs="2"> */}
        <Col md={8}>
          <ReactSelect
            isClearable
            id="selectedNotReadyAction"
            name="selectedNotReadyAction"
            placeHolder={getLocaleMessage(intl, ACTIONS_PAGE.SELECT_OPTION)}
            control={control}
            components={{ SingleValue }}
            value={selectedNotReadyAction}
            options={actionNotReadyResponseVM}
            onSelect={(selected) => onChangeDetails("selectedNotReadyAction", selected ? selected.value : "")}
          />
          {isOpenRescheduleDatePicker && <DatePicker
            id="rescheduleDate"
            wrapperClassName="not-ready-reschedule-date"
            open
            value={rescheduleDate}
            onClickOutside={onCloseDatePicker}
            onChange={(value) => onChangeDetails("rescheduleDate", value)}
            isClearable={!!rescheduleDate}
          />}
        </Col>
        <Col md={4} className="card-action-btn my-0">
          <ActionButton
          className={cx("w-100", { "grey-solid": isDisabledView, "submit-button": !isDisabledView })}
            labelLocaleMessageId={ACTIONS_PAGE.SUBMIT}
            isSubmitAction
            isDisabled={isDisabledView}
            onClick={onSubmit}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {isShowAddNoteView && (
            <div className="note-detail">
              <Input
                type="textarea"
                placeholder={getLocaleMessage(
                  intl,
                  ACTIONS_PAGE.ADD_NOTE_PLACEHOLDER
                )}
                value={noteDetail}
                onChange={onChangeNoteDetail}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className="card-action-btn my-0">
        <Col md={6}>
          <ActionButton
            className="grey-button w-100"
            labelLocaleMessageId={BACK}
            onClick={onShowConfirmActionView}
          />
        </Col>
        <Col md={6}>
          <ActionButton
            className={cx("w-100", {
              "grey-button": !isShowAddNoteView
            })}
            color={isShowAddNoteView ? "danger" : undefined}
            labelLocaleMessageId={
              isShowAddNoteView
                ? ACTIONS_PAGE.REMOVE_NOTE
                : ACTIONS_PAGE.ADD_NOTE
            }
            onClick={onToggleAddNote}
          />
        </Col>
      </Row>
      <h5 className="mt-1 attention-description">
        {getLocaleMessage(intl, ACTIONS_PAGE.NOT_READY_WARNING_TEXT, {
          attentionText: (
            <b>{getLocaleMessage(intl, ACTIONS_PAGE.ATTENTION)}</b>
          ),
          toBeProvidedText: (
            <b>{getLocaleMessage(intl, ACTIONS_PAGE.TO_BE_PROVIDED)}</b>
          ),
          pleaseContactMeText: (
            <b>{getLocaleMessage(intl, ACTIONS_PAGE.PLEASE_CONTACT_ME)}</b>
          )
        })}
      </h5>
    </>
  )
}

export default localeMessageWrapper(ActionsView)
